<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <div>
            <h1 class="intro-section-title" v-if="selectedLevel === 'zorgkantoor' || selectedLevel === 'dementienetwerk'">4. Maatschappelijke ondersteuning Wmo {{selectedYear}}</h1>
            <h1 class="intro-section-title" v-if="selectedLevel === 'landelijk'">4. Maatschappelijke ondersteuning Wmo</h1>
            <div class="row">
              <p class="intro-section-text">
                <b>Toelichting</b>: Onderstaand dashboard geeft informatie over de Wmo (wet maatschappelijke ondersteuning) als onderdeel van het levenspad Diagnose. Het geeft inzicht in het gebruik van langdurige zorg gefinancierd vanuit de Wmo door mensen met dementie. Specifiek wordt in dit dashboard als onderdeel van de levenspadfase diagnose gefocust op informatie over de Wmo.
              </p>
              <p class="intro-section-text">
                <b>Populatie</b>: De populatie die gebruikt wordt voor de inzichten met betrekking tot de Wmo-voorzieningen zijn bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. Van deze populatie wordt in onderstaand dashboard het percentage weergegeven dat gebruik maakt van de beschreven voorziening.
              </p>
            </div>
          </div>
        </div>
      </div>


        <!-- <h1>4. Maatschappelijke ondersteuning Wmo {{selectedYear}}</h1>
        <p class="paragraph"><b>Toelichting:</b> Onderstaand dashboard geeft informatie over de Wmo (wet maatschappelijke ondersteuning) als onderdeel van het levenspad Diagnose. Het geeft inzicht in het gebruik van langdurige zorg gefinancierd vanuit de Wmo door mensen met dementie. Specifiek wordt in dit dashboard als onderdeel van de levenspadfase diagnose gefocust op informatie over de Wmo. </p>
        <p class="paragraph"><b>Populatie:</b> De populatie die gebruikt wordt voor de inzichten met betrekking tot de Wmo-voorzieningen zijn bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. Van deze populatie wordt in onderstaand dashboard het percentage weergegeven dat gebruik maakt van de beschreven voorziening.</p> -->
        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Gebruik Wmo voorziening ${selectedYear}`" :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat enige vorm van Wmo-zorg ontvangt.'" 
              :x="zorgkantoorregionaam_wmo" :y="perc_wmo_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Gebruik Wmo voorziening {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van minimaal één Wmo-gefinancierde voorziening. Er is geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn.</p>
                  <stacked-bar-chart-colors v-if="load.graphOne" 
                  :ytitle="'% met Wmo gebruik'" 
                  :names="['%Gebruikt Wmo voorziening']" 
                  :x="[zorgkantoorregionaam_wmo]" 
                  :y="[perc_wmo]"
                  :ticksuffix="'%'"
                  :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Gebruik ondersteuning thuis ${selectedYear}`" :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde ondersteuning thuis ontvangt.'" 
              :x="zorgkantoorregionaam_ot" :y="perc_ondersteuning_thuis_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Gebruik ondersteuning thuis {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo-gefinancierde ondersteuning thuis. Ondersteuning thuis omvat persoonlijke verzorging, begeleiding, kortdurend verblijf, dagbesteding en andere groepsgerichte ondersteuning. </p>
                  <stacked-bar-chart-colors v-if="load.graphOne" 
                  :ytitle="'% met ondersteuning thuis'" 
                  :names="['%Gebruikt ondersteuning thuis']" 
                  :x="[zorgkantoorregionaam_ot]" 
                  :y="[perc_ondersteuning_thuis]"
                  :ticksuffix="'%'"
                  :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Gebruik hulp bij huishouden ${selectedYear}`" :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulp bij het huishouden ontvangt.'" 
              :x="zorgkantoorregionaam_hh" :y="perc_hulphuis_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Gebruik hulp bij huishouden {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext"> Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo-gefinancierde hulp bij huishouden. Hulp bij huishouden omvat alle ondersteuning bij het huishouden. </p>
                  <stacked-bar-chart-colors v-if="load.graphOne" 
                  :ytitle="'% met hulp bij huishouden'" 
                  :names="['%Gebruikt hulp huishouden']" 
                  :x="[zorgkantoorregionaam_hh]" 
                  :y="[perc_hulphuis]" 
                  :ticksuffix="'%'"
                  :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Gebruik hulpmiddelen en diensten ${selectedYear}`" :hovertext="'De populatie bestaat uit bewoners bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulpmiddelen of diensten ontvangt.'" 
              :x="zorgkantoorregionaam_hd" :y="perc_hulpdienst_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Gebruik hulpmiddelen en diensten {{selectedYear}}</template>
              <div slot="card-body">
                  <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo-gefinancierde hulpmiddelen en diensten. Als onderdeel van hulpmiddelen en diensten worden alle woon- en vervoersdiensten en voorzieningen, rolstoelen en overige hulpmiddelen meegenomen in het overzicht. </p>
                  <stacked-bar-chart-colors v-if="load.graphOne" 
                  :ytitle="'% met hulpmiddelen en diensten'" 
                  :names="['%Gebruikt hulpmiddelen en diensten']" 
                  :x="[zorgkantoorregionaam_hd]" 
                  :y="[perc_hulpdienst]" 
                  :ticksuffix="'%'"
                  :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Type maatschappelijke ondersteuning, totale populatie mensen met dementie vs algemene bevolking in ${selectedYear}`" :hovertext="'De populatie `thuiswonende mensen met dementie` bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. Informatie over de algemene bevolking komt uit https://opendata.cbs.nl/#/CBS/nl/dataset/83262NED.'" 
              :x="bevolkingsgroep" :y="ondersteuning_thuis_table.map(value => `${value}%`)" :z="hulp_bij_huishouden_table.map(value => `${value}%`)" :w="hulpmiddelen_diensten_table.map(value => `${value}%`)"
              :xtitle="'Groep'" :ytitle="'Ondersteuning thuis'" :ztitle="'Hulp bij Huishouden'" :wtitle="'Hulpmiddelen en Diensten'" 
              :tableHeight="400">
              <template slot="card-header">Type maatschappelijke ondersteuning, totale populatie mensen met dementie vs algemene bevolking in {{selectedYear}}</template>
              <div slot="card-body" v-if="selectedYear !== 2021">
                Onderstaande grafiek maakt inzichtelijk hoeveel procent van een bepaalde bevolkingsgroep gebruik maakt van Wmo-gefinancierde ondersteuning thuis, hulp bij huishouden en of hulpmiddelen en diensten. Deze percentages worden gegeven voor de groep mensen met dementie, mensen van 60 jaar en ouder in de algemene bevolking en voor de mensen ouder dan 85 in de algemene bevolking.
                <grouped-bar-chart :ytitle="'% met maatschappelijke ondersteuning'" v-if="load.graphTwo" :tickformat="'%'" :tickangle="0"
                :names="['Ondersteuning thuis','Hulp bij Huishouden','Hulpmiddelen en Diensten']"
                :x="[bevolkingsgroep,bevolkingsgroep,bevolkingsgroep]"
                :y="[ondersteuning_thuis,hulp_bij_huishouden,hulpmiddelen_diensten]"
                />
              </div>
              <div slot="card-body" v-if="selectedYear === 2021">
                Deze grafiek is nog niet beschikbaar voor <em><b class="blue">2021</b></em>.
              </div>
            </card>
          </div>
        </div>

        <!-- Landelijk Plots -->
        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Gebruik Wmo voorziening'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="ondersteuning_jaar" :y="perc_wmo_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="400">
                <template slot="card-header">Gebruik Wmo voorziening</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gemiddeld gebruik maakt van minimaal één Wmo-voorziening. Er is een selectie gemaakt voor mensen met dementie die het hele jaar door geen gebruik maken van de Wlz-zorg en thuiswonen.
                  <stacked-bar-chart-colors v-if="load.graphThree" :isLandelijk='false'
                    :ytitle="'% met Wmo gebruikt'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[ondersteuning_jaar]"
                    :y="[perc_wmo]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Gebruik ondersteuning thuis'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="ondersteuning_jaar" :y="perc_ondersteuning_thuis_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Gebruik ondersteuning thuis</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat landelijk gemiddeld gebruik maakt van Wmo-ondersteuning aan huis. Ondersteuning aan huis omvat persoonlijke verzorging, begeleiding, kortdurend verblijf, dagbesteding en andere groepsgerichte ondersteuning.
                  <stacked-bar-chart-colors v-if="load.graphThree" :isLandelijk='false'
                    :ytitle="'% met ondersteuning thuis'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[ondersteuning_jaar]"
                    :y="[perc_ondersteuning_thuis]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Gebruik hulp bij huishouden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="ondersteuning_jaar" :y="perc_hulphuis_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Gebruik hulp bij huishouden</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat landelijk gemiddeld gebruik maakt van Wmo-huishoudelijke hulp. Huishoudelijke hulp omvat alle ondersteuning bij huishoudelijk werk.
                  <stacked-bar-chart-colors v-if="load.graphThree" :isLandelijk='false'
                    :ytitle="'% met hulp bij huishouden'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[ondersteuning_jaar]"
                    :y="[perc_hulphuis]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Gebruik hulpmiddelen en diensten'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="ondersteuning_jaar" :y="perc_hulpdienst_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Gebruik hulpmiddelen en diensten</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat landelijk gemiddeld gebruik maakt van Wmo-hulpmiddelen en -diensten. Als onderdeel van hulpmiddelen en diensten zijn alle woon- en vervoersdiensten en -voorzieningen, rolstoelen en andere hulpmiddelen in het overzicht opgenomen.
                  <stacked-bar-chart-colors v-if="load.graphThree" :isLandelijk='false'
                    :ytitle="'% met hulpmiddelen en diensten'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[ondersteuning_jaar]"
                    :y="[perc_hulpdienst]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>

// import SideBarRight from '../components/SideBarRight'
import StackedBarChartColors from '../components/StackedBarChartColors'
import GroupedBarChart from '../components/GroupedBarChart'
import Card from '../components/Card'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Maatschappelijke Ondersteuning',
  },
  components: { Card, StackedBarChartColors, GroupedBarChart, Footer },
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      perc_maatschappelijke: [],
      perc_hulpdienst_table :[],
      perc_hulphuis_table :[],
      perc_ondersteuning_thuis_table :[],
      perc_wmo_table :[],
      zorgkantoorregionaam_wmo: [],
      zorgkantoorregionaam_ot: [],
      zorgkantoorregionaam_hh: [],
      zorgkantoorregionaam_hd: [],
      ondersteuning_jaar:[],
      perc_wmo: [],
      perc_ondersteuning_thuis: [],
      perc_hulphuis: [],
      perc_hulpdienst: [],
      perc_dagopvang: [],
      ondersteuning_thuis_table :[],
      hulp_bij_huishouden_table :[],
      hulpmiddelen_diensten_table :[],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false
      },
      hulp_bij_huishouden: [],
      ondersteuning_thuis: [],
      hulpmiddelen_diensten: [],
      bevolkingsgroep: []
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadBevolkingOndersteuning()
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_wmo-a.percentage_wmo)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.perc_wmo = response.data.map(d => (d.percentage_wmo*100))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_ondersteuning_thuis-a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis*100))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_hulp_huishouden-a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden*100))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_hulpmiddelen_diensten-a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100))
            
            this.zorgkantoorregionaam_hd = response.data.map(d => d.zorgkantoorregionaam)
            // this.perc_dagopvang = response.data.map(d => (d.percentage_dagopvang))

            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo*100).toFixed(1))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100).toFixed(1))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden*100).toFixed(1))
            this.perc_ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis*100).toFixed(1))
            this.load.graphOne=true
          }
        )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.ondersteuning_jaar = response.data.map(d => d.jaar)
            this.perc_wmo = response.data.map(d => (d.percentage_wmo*100))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_ondersteuning_thuis-a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis*100))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_hulp_huishouden-a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden*100))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_hulpmiddelen_diensten-a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100))
            this.zorgkantoorregionaam_hd = response.data.map(d => d.zorgkantoorregionaam)

            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo*100).toFixed(1))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100).toFixed(1))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden*100).toFixed(1))
            this.perc_ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis*100).toFixed(1))

            this.load.graphThree=true
          }
        )
      }
      else {
        this.$http.get(`/api/dementienetwerk_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_wmo-a.percentage_wmo)
            response.data = response.data.filter(x => x.dementienetwerknaamregionaam !=='Missing')
            this.perc_wmo = response.data.map(d => (d.percentage_wmo*100))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a,b) => b.percentage_ondersteuning_thuis-a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis*100))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a,b) => b.percentage_hulp_huishouden-a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden*100))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a,b) => b.percentage_hulpmiddelen_diensten-a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100))
            // this.perc_dagopvang = response.data.map(d => (d.percentage_dagopvang))
            this.zorgkantoorregionaam_hd = response.data.map(d => d.dementienetwerknaam)

            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo*100).toFixed(1))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100).toFixed(1))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden*100).toFixed(1))
            this.perc_ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis*100).toFixed(1))
            this.load.graphOne=true
          }
        )
      }
    },
    loadBevolkingOndersteuning () {
      if (this.$store.state.selectedYear === 2021){
        return {}
      } else {
        this.$http.get(`/api/bevolking_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.bevolkingsgroep = response.data.map(d => d.groep)
            this.ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis))
            this.hulp_bij_huishouden = response.data.map(d => (d.percentage_hulp_huishouden))
            this.hulpmiddelen_diensten = response.data.map(d => (d.percentage_hulpmiddelen_diensten))

            this.ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis*100).toFixed(1))
            this.hulp_bij_huishouden_table = response.data.map(d => (d.percentage_hulp_huishouden*100).toFixed(1))
            this.hulpmiddelen_diensten_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten*100).toFixed(1))
            this.load.graphTwo=true
          }
        )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
    this.loadBevolkingOndersteuning()
  }
}

</script>
<style scoped>

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}


.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

/*.intro-section-text{
  color: #1f2a44;
}*/
</style>
